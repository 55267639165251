/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Bratislava"), React.createElement("p", null, "Pour se loger, Bratislava s’articule en trois quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/sk/bratislava/stare-mesto.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Staré Mesto"), " : la vieille ville regroupe l’essentiel des attractions, des bars et restaurants."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/sk/bratislava/novemesto.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Nové Mesto"), ", au nord-ouest : plutôt calme, la nouvelle ville est au pied des Petites Carpates."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/sk/bratislava/petrzalka.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Petrzalka"), ", sur la rive sud du Danube : de grands ensembles hérités de la période communiste."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
